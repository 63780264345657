import React, {useEffect, useState} from 'react';
import _ from 'underscore';

import {
    Link, Outlet
} from "react-router-dom";
import Smart from 'libs/Smart';
import {Autocomplete, Box, TextField} from '@mui/material';


let timer;

function Layout2({props}) {
    let {item = {}} = props || {}
    let userId = item._id;
    let [courses, setCourses] = useState([]);
    let [removedCourses, setRemovedCourses] = useState([]);
    let [allCourses, setAllCourses] = useState([]);

    function get_name(course) {
        course = course || {};
        return `${course.name} #${course._id}`
    }
    function regenerate (localItem) {
      //console.log("qqqqq regenerate course", localItem );
        localItem.course && global.http.get('/regenerate-course', {
            _id: localItem._id,
            course: localItem.course,
            user: localItem.user
        }).then(({data}) => {
            localItem = {localItem, ...data}
            setCourses(courses)
        })
    }

  //console.log('*...Coures User Selector', props);
    useEffect(() => {
        syncAll();
    }, [userId])

    function syncAll() {
        Promise.all([
            userId && global.http.get('/get-user-courses', {user: props.item._id}),
            global.http.get('/course', {per_page: 100000})
        ]).then(([{courses, removedCourses}, allCourses]) => {
          //console.log("rrrrrrrrrr ALL COURSES", allCourses)
            setCourses(courses || [])
            setRemovedCourses(removedCourses || [])

            setAllCourses((allCourses || {}).items)
        })
    }

    function syncUserCourses(courses, cb) {
      //console.log("sync user course!!!s", courses)
        courses.map((it, ind) => {
            it.order = ind
            return it;
        })
        global.http.post('/sync-user-courses', {courses, user: userId}).then(({courses, _removedCourses}) => {
          //console.log("ccccc", courses)
            setCourses(courses || []);
            setRemovedCourses(_.uniq([...removedCourses, ..._removedCourses], it => it._id))
            cb && cb()
        })
    }

    // let v = useActionData();
    return <div>
        Курсы:
        <Smart
            obj={{courses}}
            items={[
                {
                    key: 'courses', size: 12,
                    sortable: true,
                    defSize: 2, each: [
                        {
                            size: 3, Component: ({localItem}) => {
                                return <Autocomplete
                                    openOnFocus={true}
                                    sx={{width: '100%'}}
                                    options={allCourses || []}
                                    value={allCourses.filter(it => it._id == localItem.course)[0]}
                                    autoHighlight
                                    onChange={(e, el) => {
                                        let oldCourse = localItem.course;

                                        localItem.course = (el || {})._id || 0;
                                        syncUserCourses(courses, (x) => {
                                            // console.log('xxxxxxxx', x)
                                            (oldCourse !== localItem.course) && regenerate(localItem)
                                        })
                                    }}
                                    getOptionLabel={(option) => {
                                        return get_name(option);
                                    }}
                                    renderOption={(props, option) => {
                                        return <Box component="li" sx={{'& > img': {mr: 2, flexShrink: 0}}} {...props}>
                                            <span>{get_name(option)}</span>
                                        </Box>
                                    }}
                                    renderInput={(params) => {
                                        return <TextField
                                            {...params}
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'new-password', // disable autocomplete and autofill
                                            }}
                                        />
                                    }}
                                />
                            }
                        },
                        {
                            size: 3,
                            key: 'status',
                            type: 'group',
                            title: 'Status',
                            list: ['active', 'unactive', 'removed']
                        },
                        {size: 3, key: 'endDate', name: 'endDate', type: 'date'},
                        {
                            Component: ({localItem}) => {
                                return <>
                                    <button onClick={() => {
                                       regenerate(localItem)
                                    }}>Regenerate User's History
                                    </button>
                                    <button onClick={() => {
                                        global.http.get('/clear-course', {
                                            _id: localItem._id,
                                            user: localItem.user
                                        }).then(({data}) => {
                                            window.location.reload();
                                            localItem = {localItem, ...data}
                                            setCourses(courses)
                                        })
                                    }}>Clear CourseHistory to Zero
                                    </button>
                                </>
                            }
                        },
                        // { key: '_id', name: '_id', type: 'text'} ,


                    ]
                }
            ]}
            onChange={({courses}) => {
              //console.log("coursesessese", courses)
                clearTimeout(timer)

                if (courses.length) {
                    syncUserCourses(courses)
                } else {
                    timer = setTimeout(() => {
                        syncUserCourses(courses)
                    }, 300)
                }
                // console.log("user courses change", courses)
            }}
        ></Smart>
        <hr/>
        <div>Removed / Finished Courses</div>
        {removedCourses.map(course => {
            return <div>
                #{course._id} {course.name}
                <div className='ib'>
                    <button onClick={() => {
                        global.http.get('/make-course-active', {user: userId, course: course._id}).then(r => {
                            syncAll()
                        })
                    }}>Make Course Active
                    </button>
                </div>
            </div>
        })}
    </div>
}

export default Layout2
