import React, {useEffect, useState} from 'react';
import _ from 'underscore';
import Editor from '@monaco-editor/react';
import {
    Link, Outlet
} from "react-router-dom";
import './ExamAdminHistory.css';
import Button from 'libs/Button'
import QuestionDetails from "./Suggest/QuestionDetails";
import QuizQuestion from "./Suggest/QuizQuestion";
import CustomStorage from "./Suggest/CustomStorage";
import RenderQuizResults from "./Suggest/RenderQuizResults";
import LogsStarterPreview from "./Suggest/LogsStarterPreview";
import MyModal from "../libs/MyModal";
import {Daily} from "./Stats/UserStatsOld";
import {toOdb} from "./Stats";

let MAX_LENG_HISTORY = 180;

window.intervalInd = window.intervalInd || 0
clearInterval(window.intervalInd)
window.pause = true;
window.speed = 20;
window.intervalInd = setInterval(() => {
    if (window.pause) return;
    window._setHistoryInd && window._setHistoryInd()
}, 100);

function PlayHistory(props) {
    let [cd, setCd] = useState(0)
    // let [files, setFiles] = useState([{ name: '' }]);
    // let [files, setFiles] = useState([{ name: 'index.js' }, { name: 'app.css' }, { name: '' }]);
    let [historyInd, setHistoryInd] = useState(0)
    let [maxHistory, setMaxHistory] = useState(1)
    let [curHistory, setCurHistory] = useState([])
    let {questionId, history = []} = props
    window._setHistoryInd = (delta = 1) => {
        let leng = history.length;

      //console.log('history id', historyInd, history.length)
        setHistoryInd((historyInd + delta + leng) % leng)
    }
    window.histLeng = history.length;

    useEffect(() => {
        // clearInterval(window.intervalInd)

    }, [])
    useEffect(() => {
        maxHistory = 0
        _.each(history, r => {
            maxHistory = Math.max(maxHistory, r.size)
        })
        setMaxHistory(maxHistory)
        setHistoryInd(history.length - 1)
        let leng = history.length;
        let curHistory = []

        function getPerc(size) {
            return 90 * (size / (maxHistory || 1)) + '%'
        }

        if (leng < MAX_LENG_HISTORY) {
            curHistory = history.map((item, ind) => {
                return {
                    perc: getPerc(item.size),
                    from: ind,
                    to: ind + 1
                }
            })
        } else {

            let prevInd = 0;
            let k = leng / (MAX_LENG_HISTORY || 1)
            for (let i = 1; i <= MAX_LENG_HISTORY; i++) {
                let curInd = Math.round(k * i);
                let item = history[Math.min(curInd, leng - 1)] || {}
              //console.log('curind', curInd, item)
                curHistory.push({
                    perc: getPerc(item.size),//80 * (item.size / (maxHistory || 1)) + '%',
                    from: prevInd,
                    to: curInd
                })
                prevInd = curInd;
            }
          //console.log('curHistory', k, curHistory[0], curHistory[1])

        }
        setCurHistory(curHistory)
    }, [questionId, history.length])

    let hist = (history || [])[historyInd] || {}

    let files = Object.keys((hist || {}).files || {'': true}).map(it => {
        return {name: it}
    });
    let code = hist.logsReader ? hist.logsReader : (hist.files || {})[''];

    return <div>
        <button className={'btn btn-xs btn-default'} onClick={() => {
            window.pause = !window.pause
            setCd(new Date())
        }}>{window.pause ? 'Continue auto' : 'Stop Auto'}</button>
        <hr/>
        <div className="historyWrap">
            {curHistory.map(({perc, from, to}, ind) => {
                let isActive = (from <= historyInd) && (historyInd < to)
                // from < 10 && console.log('from , to', isActive, from <=historyInd, to > historyInd, from, to, historyInd)
                return <div key={ind}
                            className={isActive ? 'active' : ''}
                            onClick={() => {
                                window.pause = true;
                                setHistoryInd(from)
                            }}
                >
                    <div
                        style={{height: perc}}
                    ></div>
                </div>
            })}</div>
        Индекс прокрутки истории: {historyInd + 1} из {(curHistory || []).length}
        <div>
            Длина кода: {hist.size}
        </div>

        <div className="row">
            {files.map((it, ind) => {
                return <div key={ind} className={'col-sm-' + (12 / (files.length || 1))}>{it.name || '-'}
                    <Editor
                        height={'500px'}
                        language={'javascript'}
                        value={hist.logsReader || (hist.files || {})[it.name || '']}></Editor>
                </div>
            })}
        </div>

        <hr/>
    </div>

}

function ExamAdminHistory(props) {
    function getExamId() {
        return window.location.href.split('-history/')[1]
    }

    let [open, setOpen] = useState(false);
    let [questionId, setQuestionId] = useState(1026)
    let [examId, setExamId] = useState(getExamId())
    let [dbQuestions, setDbQuestions] = useState({})
    let [exam, setExam] = useState({})
    let [history, setHistory] = useState({})

  //console.log('*........ ## ROOT RENDER', props);

    useEffect(() => {
        global.http.get('/history-full-details', {
            questionId,
            examId
        }).then(r => {
            let {history, exam, dbQuestions} = r;

            setQuestionId(exam.questions[0])

            setDbQuestions(_.indexBy(dbQuestions, '_id'))
            setExam(exam)
            setHistory(_.groupBy(history, 'question'))
        })
    }, [])

    let curHistory = history[questionId] || []
    let curSubmit = (exam.submitDetails || {})[questionId] || {}

    function last(arr) {
        if (!arr || !arr.length) {
            return {}
        }
        return arr[arr.length - 1]
    }

    function recalcAdminSubmit() {
        setExam({...exam});
        global.http.post('/recalc-exam', {exam})
            .then(dbExam => {
                setExam(dbExam)
              //console.log("qqqqq recalc exam", dbExam);
            })

    }

    function getTime (exam) {
        return (((new Date(exam.submitCd).getTime() - new Date(exam.startCd).getTime()) / (1000 * 60)).toFixed(0) || '--') + ' мин'
    }

    function time (v) {
        if (!v) {
            return ''
        }
        return v.toString().replace('T', ' :: ').replace(/\.[0-9]+z/gi, '')

    }

    function onChangeExam (v) {
      //console.log("qqqqq ON CHANGE EXAM",v );
        setExam(v)
    }






    let examDate = toOdb(exam.submitCd || exam.startCd);
  //console.log('historyhistoryhistory', dbQuestions, history)
  //console.log('rrrrrrr', (exam.quizQuestionsPlain || []).map(it => it._id))

    return <div className="row" tabIndex="0" onKeyDown={(e) => {
        let {keyCode, altKey, ctrlKey, metaKey, shiftKey} = e;
        let isSpecial = altKey || ctrlKey || metaKey || shiftKey;

        if (keyCode === 37 || keyCode === 39) {
            window.pause = true;
            window._setHistoryInd && window._setHistoryInd((keyCode === 37 ? -1 : 1) * 1 * (isSpecial ? 10 : 1))
        }
    }}>
        <div className="col-xs-6">
        <div>Статус: {exam.status}</div>
        <div>Старт: {time(exam.startCd)}</div>
        <div>Сабмит: {time(exam.submitCd)}</div>
        <div>Время выполнения: {getTime(exam)}</div>

        <a onClick={() => {setOpen(true)}}>Активность этого дня {examDate}</a>
            <MyModal size={'full'} isOpen={open} onClose={() => setOpen(false)}>
                <Daily userId={exam.user} odb={examDate}></Daily>
            </MyModal>
            <div></div>
            <Link to={'/users/' + exam.user}>Пользователь #{exam.user}</Link>
        </div>
        <div className="col-xs-6">
            <TestResults exam={exam}></TestResults>
        </div>

        {history['-1'] && <div className="col-sm-12">
            <RenderQuizResults
                onChangeExam={onChangeExam}
                exam={exam} history={{'-1': last(history['-1'])}}></RenderQuizResults>
            <hr/>
        </div>}
        <div className="col-sm-3">
            {(exam.questions || []).map((_id) => {
                let submitDetails = (exam.submitDetails || {})[_id] || {}
                return <div
                    className={'questions-wrap ' +
                        (questionId === _id ? ' active ' : '') +
                        (submitDetails.isAdminValidation && !submitDetails.isAdminForce ? 'adminValidation o4' : '')}
                    onClick={() => {
                        setQuestionId(_id)
                    }}>{submitDetails.isAdminForce ? '*' : ''}{submitDetails ? ((submitDetails.perc || 0) + '%') : '--'} x{(history[_id] || []).length} {CustomStorage.pubName((dbQuestions[_id] || {}).name || '-')}</div>
            })}
            <hr/>
            <Button onClick={(scb, ecb) => {
                global.http.get('/start-full-exam', {_id: examId})
                    .then(r => {
                        scb && scb();
                        setExam(r)
                    })
            }}>Submit Full Test</Button>
            <hr/>
        </div>
        <div className="col-sm-9">
            {curSubmit && curSubmit.isAdminValidation && <>
                {(global.m.from_to(0, 10) || []).map((it, ind) => {
                    let value = it * 10;
                    return (<button
                        className={'btn btn-xs btn-default ' + (curSubmit.perc === value ? ' active btn-success' : '')}
                        key={ind}
                        onClick={() => {
                            curSubmit.isAdminForce = true;
                            curSubmit.perc = value;
                            curSubmit.score = value / 100;
                            recalcAdminSubmit(curSubmit)
                        }}
                    >
                        {value}

                    </button>)
                })}
            </>}
            <hr/>
            <PlayHistory
                history={curHistory}
                questionId={questionId}
            ></PlayHistory>
            <QuizQuestion
                woAction={true}
                question={dbQuestions[questionId]}
                // onChange={(q) => {
                //   setIsShown(1)
                //   setSelectedQuestionsInd((selectedQuestionsInd + 1) % selectedQuestions.length)
                // }
                // }
            ></QuizQuestion>
            {<div>
                <LogsStarterPreview _id={questionId}></LogsStarterPreview>
            </div>}
            <QuestionDetails
                showSolution={true}
                question={dbQuestions[questionId]}></QuestionDetails>

        </div>

        {/* {historyItems.map((item, ind) =>{
      return <div key={ind}>{(item.files || {})['']}</div>
    })} */}
    </div>
}

function TestResults(props) {
    let {submitDetails, scoreDetails} = props.exam || {};
    submitDetails ??= {}
    scoreDetails ??= {}
    console.log("qqqqq submitDetails", submitDetails, scoreDetails);
    return <div>Test Results:
        <div>Всего задач: {scoreDetails.totalQuestions || 0}</div>
        <div>% выполнения: {scoreDetails.perc || 0}%</div>
        <div>% квиза: {scoreDetails.quizPerc || 0}%</div>
    </div>

}


export default ExamAdminHistory
