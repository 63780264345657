import React, {useState, useEffect} from 'react';
import Input from "../libs/Input";
import Textarea from "../libs/Textarea";
import Button from "../libs/Button";
import {Link} from "react-router-dom";
import TagsComp from "./TagsComp";
import Tree from "./Tree";
import Storage from "./Storage";
import MyModal from "../libs/MyModal";
import {getActiveClass} from "./Suggest/SuggestionItem";
import Duplicates from "./Duplicates";
import DuplicatePreview from "./Suggest/DuplicatePreview";

let tags1 = [];
let tags2 = [];

function DuplicatesNew(props) {
    let [selQuestion, setSelQuestion] = useState({})
    let [questions, setQuestions] = useState([])
    useEffect(() => {
        // global.http.get('/questions-on-review', {}).then(r => {
        global.http.get('/interview-question', {
            filter:
                {
                    name: {$exists: true},
                    themeQuestionId: {$exists: false}
                },
            sort: '-type -_id',
            per_page: 50
        }).then(r => {
            setQuestions(r.items)
            setSelQuestion(r.items[0])
        })
    }, [])
    return <div>
        <div className="row">
            <div className="col-xs-3">
                Questions To Find {selQuestion?._id}
                {(questions || []).map((it, ind) => {
                    return (<div
                        onClick={() => {
                            setSelQuestion(it)
                        }}
                        key={ind} className={'w100 ellipse ' + getActiveClass(it._id, selQuestion?._id)}>
                        {/*#{it._id}*/}
                        <div
                            className="label label-success mr-5">{it.themeQuestionId ? 'Проверено' : 'На проверке'}</div>
                        {/task/gi.test(it.type) && <div className="label label-primary mr-5">КОД</div>}

                        {it.name}
                    </div>)
                })}
            </div>
            <div className="col-xs-9">
                <DuplicateItem
                    onNext={(r) => {
                        _.each(questions, (item, ind) => {
                            if (item._id == selQuestion._id) {
                                questions[ind].themeQuestionId = r?.themeQuestionId
                                setSelQuestion(questions[ind + 1])
                                setQuestions([...questions])
                            }
                        })

                    }}
                    selQuestion={selQuestion} selType={'interviewQuestion'}></DuplicateItem>
            </div>
        </div>
    </div>
}

function DuplicateItem(props) {
    let [search, setSearch] = useState(props.name || Storage.get('inputStr'))
    let [question2, setQuestion2] = useState(0)
    let [themeQuestionId, setThemeQuestionId] = useState(0)
    let [wordConfig, setWordConfig] = useState({})
    let [words, setWords] = useState([])
    let [suggestions, setSuggestions] = useState([])
    let [loading, setLoading] = useState(false)
    let [hashTags, setHashTags] = useState([])
    let [hashTagsObj, setHashTagsObj] = useState({})
    let [selectedTag, setSelectedTag] = useState(null)
    let [cd, setCd] = useState(0)
    let [plainQuestions, setPlainQuestions] = useState([])
    let [qDetails, setQDetails] = useState(null)
    let [configModal, setConfigModal] = useState(null)
    let [selTagsObj, setSelTagsObj] = useState({})
    let [q2, setQ2] = useState(null)
    let [q3, setQ3] = useState(null)
    let {selQuestion} = props || {}
//console.log('*........ ## ROOT RENDER', props);

    useEffect(() => {
        Storage.syncCategories(() => {
            setCd(new Date().getTime())
        })
        global.http.get('/get-word-config').then(r => {
            setWordConfig(r)
        })
        global.http.get('/word', {filter: {status: 'bad'}, per_page: 10000}).then(r => {
            setWords(r.items)
        })
        global.http.get('/hash-tag', {per_page: 10000}).then(r => {
            setHashTags(r.items)
            setHashTagsObj(r.items.reduce((acc, it) => ({...acc, [it._id]: it}), {}))
        })
    }, [])

    let selIds = Object.keys(selTagsObj).filter(it => selTagsObj[it]).map(it => +it);

    useEffect(() => {
        selIds && selIds.length && global.http.get('/theme-question', {filter: {hashTags: {$all: selIds}}}).then(r => {
            let qq3 = {
                arr: r.items.map(it => {
                    return {
                        item2: it
                    }
                })
            };

            setQ3(qq3)
        })
    }, [JSON.stringify(selIds)])

    useEffect(() => {
        setSelectedTag(null)
    }, [props.selQuestion?._id])

    useEffect(() => {
        selectedTag && global.http.get('/theme-question', {per_page: 500, filter: {hashTags: selectedTag}}).then(r => {
            console.log("qqqqq r.itemsssssssssssssssssss", selectedTag, r.items);
            setPlainQuestions(r.items)
        })
    }, [selectedTag])

    useEffect(() => {
        let it = props?.selQuestion || {};
        setSearch(it.title || it.name)
    }, [props?.selQuestion?._id])
    useEffect(() => {
        Storage.set('inputStr', search)
        setLoading(true)
        global.http.get('/get-search-words', {str: search}).then(r => {
            setLoading(false)
            setSelectedTag(null)
            console.log("qqqqq rrrrrrrrrrrrrrrrrr", r);
            setSuggestions({words1: r?.v1?.wordsObj, words2: r?.v2?.wordsObj, v1: r.v1.arr, v2: r.v2.arr})
        })
    }, [search, configModal, question2?._id]);

    let onChangeWordConfig = (data) => {
        wordConfig = {...wordConfig || {}, ...data || {}}
        setWordConfig(wordConfig)
        global.http.put('/word-config', {item: wordConfig}).then(r => {
            console.log("qqqqq word config saved", r);
        })
    }
    let onTag = (_id) => {
        setSelectedTag(_id)
    }
    let onQuestion = (v) => {
        console.log("qqqqq Q DETAILSSSSSSSSSSSSSSSSS", v);
        global.http.get('/theme-question/' + v?.item2?._id).then(r => {
            // setQuestion2({...r, ...v?.item2})
            setQDetails({...v, item2: {...r, ...v?.item2}})
        })
    }
    let onQuestionMain = (v, closeWindow) => {
        console.log("qqqqq ON QUIESTION MAIN !!!!!", v, props);
        setQ2(v?.item2)
        if (props?.selType == 'interviewQuestion') {
            let _themeQuestionId = +v?.item2?._id || 0;
            if (!_themeQuestionId) {
                console.log("qqqqq ZZZZZZZZZZZZZZZZZZZZZZZZ", _themeQuestionId);

                return setThemeQuestionId(-1)
            }
            if (closeWindow) {
                setThemeQuestionId(0)
                setSelTagsObj({})
            }
            console.log("qqqqq _themeQuestionId_themeQuestionId_themeQuestionId", v, _themeQuestionId);
            onSend(_themeQuestionId)
        } else {
            global.http.get('/theme-question/' + v?.item2?._id).then(r => {
                setQuestion2({...r, ...v?.item2})
            })
            // alert("wrong type: " + props?.selType)
        }
    }

    let onSend = (themeQuestionId, tags) => {
        global.http.get("/set-theme-question", {
            hashTags: getHashTags(tags),
            _id: selQuestion?._id, themeQuestionId
        }).then(r => {
            console.log("qqqqq set element", r);
            props.onNext && props.onNext({status: "ok", themeQuestionId: r.themeQuestionId})
        })
    }
    let getHashTags = (tags) => {
        return tags || []
    }

    let onQuestionNew = (v) => {
        setQ2({})
    }

    let getThemeQLink = (_id) => {
        return `/theme-question/${_id}`
    }

    let onClickPotentialTag = (v) => {
        selTagsObj[v] = !selTagsObj[v]
        setSelTagsObj({...selTagsObj})
        console.log("qqqqq seltags obj", selTagsObj);
        // onSend(0, [v])
        // setThemeQuestionId(0)
    }

    let parentCategory = Storage.getParentCategory(selectedTag)
    let q = qDetails?.item2 || {};
    let qTag = (q.hashTags || [])[0] || null
    let isInterview = props?.selType == 'interviewQuestion'
    // let v = useActionData();
    if (!selQuestion) {
        return <div>Нет выбранных элементов</div>
    }
    return <div className="row">
        <MyModal
            isOpen={question2}
            size={'full'}
            onClose={() => setQuestion2(0)}
        >
            <DuplicatePreview question1={props.selQuestion} question2={question2} onClose={() => {
                setQuestion2(0)
            }}>

            </DuplicatePreview>
        </MyModal>
        <MyModal
            isOpen={themeQuestionId}
            size={'full'}
            onClose={() => setThemeQuestionId(0)}
        >
            Tags Selector
            <div>
                {(Object.keys(selTagsObj) || []).map((key, ind) => {
                    let v = selTagsObj[key]
                    if (!v) {
                        return null;
                    }

                    let it = hashTagsObj[key]

                    return (<span key={ind} className={'label label-default mr-5'} onClick={() => {
                        selTagsObj[key] = !selTagsObj[key]
                        setSelTagsObj({...selTagsObj})
                    }}>
                    {it.name || it.title || ''}
                </span>)
                })}
                <div></div>
                <Button onClick={() => {
                    // onSend()
                    onSend(0, Object.keys(selTagsObj).filter(it => !!selTagsObj[it]))
                    setThemeQuestionId(0)
                }}>
                    Создать
                </Button>
            </div>


            <div className="row">
                <div className="col-xs-12">
                    <hr/>
                </div>
                <div className="col-xs-4">
                    <TagsSel tags={tags1} onClickPotentialTag={onClickPotentialTag} selTagsObj={selTagsObj}></TagsSel>
                    <hr/>
                    <TagsSel tags={tags2} onClickPotentialTag={onClickPotentialTag} selTagsObj={selTagsObj}></TagsSel>
                </div>

                <div className="col-xs-4">
                    <Tree
                        selectedIds={selTagsObj}
                        onClick={(v) => {
                            onClickPotentialTag(v)
                        }}></Tree>
                </div>
                <div className="col-xs-4">
                    Loaded questions with tags selector
                    <QList items={q3?.arr || []}
                           withSearch={true}
                           woTags={true}
                           selQuestion={props.selQuestion}
                           selType={props.selType}
                           hashTagsObj={hashTagsObj}
                           onInitTags={(tags) => {
                               let _id = (tags[0] || {})._id
                               if (!selectedTag && _id) {
                                   setSelectedTag(_id)
                               }
                               tags1 = tags
                           }}
                           onQuestion={onQuestion}
                           onQuestionMain={(v) => onQuestionMain(v, true)}
                           onTag={onTag}
                    ></QList>
                </div>
            </div>
        </MyModal>
        <div className="col-xs-6">
            <Textarea value={search}
                      onChange={(search) => {
                          setSearch(search)
                      }}></Textarea>
            <div>

                {(Object.keys(suggestions?.words1 || {}) || []).map((it, ind) => {
                    return (<small key={ind} className={'mr-5'}>
                        {it}
                    </small>)
                })}
                <div></div>
                {(Object.keys(suggestions?.words2 || {}) || []).map((it, ind) => {
                    if (suggestions?.words1[it]) {
                        return null
                    }
                    return (<small key={ind} className={'label label-danger mr-5'}>
                        {it}
                    </small>)
                })}


                <div>
                    Разбиение по словам
                </div>
                {q2?._id}
                <div>
                    ThemeQuestion: <Link
                    to={getThemeQLink(selQuestion.themeQuestionId)}>#{selQuestion.themeQuestionId || '---'}</Link>
                    <div>
                        <div>Interview: <Link
                            to={'/interviews/' + selQuestion.interview}>#{selQuestion.interview || '---'}</Link></div>
                    </div>
                    {props?.selType}: {selQuestion?._id}
                    {/*Блок редактирования вопроса - интервью и т.д.*/}
                </div>
                {isInterview && <div>
                    <Button
                        size={'xs'}
                        onClick={(scb) => {
                            scb();
                            onQuestionMain()
                            // onQuestionNew();
                        }}>Это новый вопрос</Button>

                </div>}

            </div>
        </div>
        <div className="col-xs-6">
            <a onClick={() => {
                setConfigModal(true)
            }}>Конфигуртор слов и окончаний</a>

        </div>
        <div className="col-xs-12">
            <hr/>
        </div>
        <div className={"col-xs-4 " + (loading ? 'o3' : '')}>
            Questions List1
            <QList items={suggestions?.v1}
                   selQuestion={props.selQuestion}
                   selType={props.selType}
                   hashTagsObj={hashTagsObj}
                   onInitTags={(tags) => {
                       let _id = (tags[0] || {})._id
                       if (!selectedTag && _id) {
                           setSelectedTag(_id)
                       }
                       tags1 = tags
                   }}
                   onQuestion={onQuestion}
                   onQuestionMain={onQuestionMain}
                   onTag={onTag}
            ></QList>
        </div>
        <div className={"col-xs-4 " + (loading ? 'o3' : '')}>
            Questions List2
            <QList items={suggestions?.v2}
                   selQuestion={props.selQuestion}
                   selType={props.selType}
                   hashTagsObj={hashTagsObj}
                   onInitTags={(tags) => {
                       // let _id = (tags[0] || {})._id
                       // if (!selectedTag && _id) {
                       //     setSelectedTag(_id)
                       // }
                       tags2 = tags

                   }}
                   onQuestion={onQuestion}
                   onQuestionMain={onQuestionMain}
                   onTag={onTag}>
            </QList>
        </div>
        <div className="col-xs-4 ">
            {/*By Hash Tags {selectedTag}*/}
            {/*<div></div>*/}
            {/*curren {hashTagsObj[selectedTag]?.title}*/}
            {/*<div></div>*/}
            {/*parent {hashTagsObj[parentCategory]?.title}*/}
            {/*<hr/>*/}
            {/*Tags Tree*/}
            <div className="fbListD">
                <Tree
                    parentCategoryIds={parentCategory ? [parentCategory] : null}
                    categoriesCount={{}}
                    defClassInput={"tree-wrap-input"}
                    defClass={"tree-wrap"}
                    selectedId={selectedTag}
                    // onSearch={(v) => {
                    //     //console.log("qqqqq vvv", v);
                    //     setSelectedTag(v)
                    // }
                    // }
                    onClick={(id) => {
                        setSelectedTag(id)
                        // window.location.hash = 'category=' + id;
                    }}></Tree>
            </div>
            <hr/>
            Tags Questions List
            {/*<QList items={plainQuestions}></QList>*/}
            <QList
                withSearch={true}
                selType={props.selType}
                selQuestion={props.selQuestion}
                woTags={true}
                items={plainQuestions.map(it => {
                    return {item2: it}
                })}
                hashTagsObj={hashTagsObj}
                onInitTags={(tags) => {
                }}
                onQuestion={onQuestion}
                onQuestionMain={onQuestionMain}
                onTag={onTag}>
            </QList>
            {/*{(plainQuestions || []).map((item, ind) => {*/}
            {/*    return (<div key={ind} className={'w100 ellipse'}>*/}
            {/*        {item.title || item.name}*/}
            {/*    </div>)*/}
            {/*})}*/}
        </div>
        <div className="col-xs-12">
            <hr/>
        </div>
        {/*{(m.from_to(0, 2) || []).map((it, ind) => {*/}
        {/*    return (<div key={ind} className={'col-xs-4'}>*/}
        {/*        Hash Tags #{ind + 1}*/}
        {/*    </div>)*/}
        {/*})}*/}
        {/*<div className="col-xs-12">*/}
        {/*    <hr/>*/}
        {/*</div>*/}
        <MyModal
            isOpen={configModal}
            onClose={() => setConfigModal(false)}
        >
            <div className="row">
                <div className="col-xs-12">
                    <hr/>
                </div>
                <div className="col-xs-6">
                    <Textarea
                        maxRows={10}
                        value={wordConfig.suffixStr} onChange={(suffixStr) => {
                        onChangeWordConfig({suffixStr})
                    }}></Textarea>
                </div>
                <div className="col-xs-6">
                    <Textarea
                        maxRows={10}
                        value={wordConfig.endpartStr} onChange={(endpartStr) => {
                        onChangeWordConfig({endpartStr})
                    }}></Textarea>
                </div>
                <div className="col-xs-12">
                    <hr/>
                </div>
                <div className="col-xs-12">
                    Слова со статусами <strong>Bad</strong>

                    <div></div>
                    <Button onClick={(cb) => {
                        global.http.post('/word', {status: "bad"}).then(r => {
                            words.unshift(r)
                            setWords([...words])
                        })
                        cb && cb()
                    }}>Add Bad Word</Button>
                    <div>
                        <hr/>
                    </div>
                    <div className="row">
                        {(words || []).map((it, ind) => {
                            return (<div key={ind} className={'col-xs-4'}>
                                {/*{it._id} {it.status}*/}
                                <Input value={it.name} onChange={v => {
                                    it.name = v;
                                    setWords([...words])
                                    global.http.put('/word', {item: it}).then(r => {
                                    })
                                }}/>
                            </div>)
                        })}
                    </div>
                </div>
            </div>
        </MyModal>
        <MyModal
            isOpen={qDetails}
            size={'full'}
            onClose={() => setQDetails(null)}
        >
            Open Question for duplicate #{q._id}
            <hr/>
            {(q.hashTags || []).map((it, ind) => {
                let cat = (Storage.getCategories() || {})[it] || {}
                return (<div key={ind}>
                    {cat.title}
                </div>)
            })}

            {/*<div className="fbListD">*/}
            {/*    <Tree*/}
            {/*        shortTree={true}*/}
            {/*        selectedId={qTag}*/}
            {/*        onClick={(v) => {*/}
            {/*            console.log("qqqqq on clickc tagggggggg", v);*/}
            {/*        }}*/}

            {/*    ></Tree>*/}
            {/*</div>*/}
            <hr/>
            <DuplicateItem
                selQuestion={q} selType={'themeQuestion'}
                name={q.title} details={{type: 'question', _id: q._id}}></DuplicateItem>
        </MyModal>
    </div>
}

function TagsSel(props) {
    let {tags, onClickPotentialTag, selTagsObj} = props


    return <>
        {(tags || []).map((it, ind) => {
            return (<div key={ind}
                         className={getActiveClass(true, selTagsObj[it._id])}
                         onClick={() => {
                             onClickPotentialTag(it._id)
                         }}>
                {it.name} {it.title}
            </div>)
        })}
    </>

}


function QList(props) {
    let {items, withSearch, selType, selQuestion, hashTagsObj = {}, woTags, onQuestionMain, onQuestion, onInitTags, onTag} = props;
    let [search, setSearch] = useState('')
    let tags = {};
    // console.log("qqqqq xxxxxxxxxxxxxxxxxxxxxxxxx", items);

    _.each(items, (it, ind) => {


        let hashTags = it?.item2?.hashTags || []
        let {count, min, max} = it?.compare || {};
        let perc = +((min + max) / 2).toFixed(1);
        _.each(hashTags, (tag, ind) => {
            tags[tag] = tags[tag] || {items: [], max: perc, total: 0, avg: 0, _id: tag, title: hashTagsObj[tag]?.title}
            tags[tag].items.push(perc)
            tags[tag].total += perc;
            tags[tag].avg = +(tags[tag].total / (tags[tag].items.length || 1)).toFixed(1)
        })
    })

    let tagsArr = _.sortBy(Object.keys(tags).map(key => tags[key]), it => (-1) * it.total)

    onInitTags && onInitTags(tagsArr)
    if (woTags) {
    }

    let reg = search ? new RegExp(search, 'gi') : null
    return <>

        {!woTags && <>
            <div className="fbListD">
                {(tagsArr || []).map((it, ind) => {
                    let {_id, max, total, title, avg} = it;
                    return (<div key={ind} onClick={() => {
                        console.log("qqqqq on CLICKCKCKCKCKCKCK", _id, onTag);
                        onTag(_id)
                    }}>
                        <small className={'ib mr-5'}>
                            {+total.toFixed(1) || '-'} {title}
                        </small>
                    </div>)
                })}
            </div>
            <hr/>
        </>}
        {withSearch && <div>
            <input type="text" placeholder={'Search by questions'} value={search} onChange={e => setSearch(e.target.value)}/>
        </div>}
        {(items || []).map((it, ind) => {
            let {item2, compare} = it || {};
            let {count, min, max} = compare || {};
            let perc = +((min + max) / 2).toFixed(1);

            if (!count && !woTags) {
                return null;
            }
            if (woTags) {
                // console.log("qqqqq TTTTTTTTTTTTTTTTTTTTTTTTT", item2);
            }
            let isOriginal = props?.selQuestion?._id == it?.item2?._id
            if (isOriginal) {
                return null;
            }

            if (reg && !reg.test(it?.item2?.name) && !reg.test(it?.item2?.title)) {
                return null
            }
            return (<div key={ind}>

                <div>

                    {(item2.hashTags || []).map((tag, ind) => {
                        return (<small key={ind} className={'ib mr-5'} onClick={() => {
                            onTag(tag)
                        }}>
                            {hashTagsObj[tag]?.title}
                        </small>)
                    })}
                    <div className="pull-right">
                    <span className="fa fa-pencil2 mr-5 o3"
                          style={{marginTop: '0px', float: 'right'}}
                          onClick={() => {
                              onQuestion(it)
                          }}
                    >дубль</span> <Link to={'/theme-question/' + it?.item2?._id} target={'_blank'}
                                        className="fa fa-pencil2 mr-5 o3"
                                        style={{marginTop: '0px', float: 'right'}}
                                        onClick={() => {
                                            onQuestion(it)
                                        }}
                    >едит</Link>
                    </div>
                </div>
                {/*to={'/theme-question/' + item2?._id}*/}
                <a
                    style={{
                        display: 'inline-block',
                        maxHeight: '75px',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                        maxWidth: '100%',
                    }}>

                    <span onClick={() => {
                        onQuestionMain(it)
                    }}>
                       {!woTags && <>{count} {perc} </>}{it?.item2?.title || it?.item2?.name || '-'}
                    </span>
                </a>

            </div>)
        })}</>
}


export default DuplicatesNew
