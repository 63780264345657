import React, { useEffect, useState } from 'react';
import _ from 'underscore';
import TagSelector from './Suggest/TagSelector'
// import GoogleDocViewer from 'react-google-docs-viewer';
import {
  Link, Outlet
} from "react-router-dom";

function getHashTags() {
  return ((window.location.hash.split('=')[1] || '').split(',') || []).map(it => +it);
}
function setHashTags(arr) {
  return window.location.hash = 'tags=' + arr.join(',')
}




function Layout2(props) {
  let [item, setItem] = useState({ hashTags: getHashTags() })
  let [exactQuestions, setExactQuestions] = useState([])
  let [otherQuestions, setOtherQuestions] = useState([])
  let [cd, setCd] = useState(0)

  function loadData() {

    global.http.get('/get-sorted-questions', { tags: getHashTags() })
      .then(r => {
        setExactQuestions(r.exact_items)
        setOtherQuestions(r.child_items)
      })
  }

  useEffect(() => {
    loadData();
  }, []);

//console.log('item', item)

  const _props = {
    // allowFullScreen: true,
    src: "https://docs.google.com/document/d/19Plzaya2IABrvx2CmVmDhxl69STxhdWhv7K0dch55jw/edit"
  };
  return <div>Preview Page
    <button onClick={() => {
      global.http.get('/reset-stats').then(r => {
        
      })
    }}>Reset Stats</button>
    <TagSelector
      props={{
        localItem: item,
        onChange: (value, key) => {
          item[key] = value;
          setHashTags(value);
          setItem(item)
          setCd(new Date())
          loadData()

        }
      }}

    ></TagSelector>

    {/* <hr/> */}
    <div style={{ marginTop: '20px' }}></div>
    <div className="row">
      <div className="col-sm-6">
        <QuestionIt items={exactQuestions} title={'Этот тег'}></QuestionIt>
      </div>
      <div className="col-sm-6">
        <QuestionIt items={otherQuestions} title={'Чайлд тег'}></QuestionIt>
      </div>
    </div>
  </div>
}

function QuestionIt({ items, title }) {
  return <div>
    <strong>х{items.length} - {title}</strong>
    <table className="quest-table" contentEditable={true}>{(items || []).map((it, ind) => {
      return <tr key={ind}><td>{it.interviewsCount || '0'}</td><td> {it.name}</td></tr>
    })
  }
  </table>
  </div>
}
export default Layout2
