import React, { useState, useEffect } from 'react';
import _ from 'underscore';

import {
  Link, Outlet
} from "react-router-dom";

function toOdb(cd) {
  let _cd = new Date(cd);
  return [_cd.getFullYear(), pub(_cd.getMonth() + 1), pub(_cd.getDate())].join('-')
}
function pub(v) {
  return v < 10 ? '0' + v : v;
}

function Stats(props) {
  let cd = new Date().getTime();
  let days = 24 * 3600 * 1000;

  let [stats, setStats] = useState({})
  let [_cd, setCd] = useState(0)
  let [start, setStart] = useState(cd - 7 * days)
  let [end, setEnd] = useState(cd)
  // useEffect(() => {
  // }, [])

  useEffect(() => {
    global.http.get('/get-stats', { start: toOdb(start), end: toOdb(end) })
      .then(r => {
        r.interviewsObj = {};
        r.themeObj = {};
        r.tagsObj = {};
        r._hashTags = [];

        function tryPush(obj, q) {
          _.each(q.hashTags, tag => {
            r._hashTags.push(tag);
            obj[tag] = obj[tag] || [];
            obj[tag].push(q)

          })
        }
        _.each(r.interviewQuestions, q => {
          tryPush(r.interviewsObj, q)
        })

        _.each(r.themeQuestions, q => {
          tryPush(r.themeObj, q)
        })

        _.each(r.hashTags, tag => {
          r.tagsObj[tag._id] = tag;
        })

        r._hashTags = _.sortBy(_.uniq(r._hashTags), it => {
          return -1 * ((r.themeObj[it] || []).length * 100 + (r.interviewsObj[it] || []).length);
        })

        setStats(r)
      });
  }, [start, end])

  let periods = [{ name: 'd', time: 1 }, { name: 'w', time: 7 }, { name: '2w', time: 14 }, { name: 'm', time: 30 },]
  let {
    interviewsObj = {}, themeObj = {}, tagsObj = {},
    _hashTags = [],
    interviews = [], themeQuestions = [], interviewQuestions = [], hashTags = [] } = stats || {};

  return <div>
    <div className='row'>
      <div className='col-sm-3'>
        <small>Start</small>
        <input type="date" value={toOdb(start)} onChange={(e) => {
          setStart(new Date(e.target.value).getTime())
        }}></input>
        <small>End</small>
        <input type="date" value={toOdb(end)} onChange={(e) => {
          let cd = new Date(e.target.value).getTime();
          setEnd(cd)
          setStart(cd - 7 * days)
        }}></input>
        <div className='periods-wrap'>
          {periods.map(it => {
            return <span onClick={(e) => {
              setStart(end - it.time * days)
            }}>{it.name}</span>
          })}
        </div>
      </div>
      <div className='col-sm-9 interviews-group'>
        <div className="stats-info">
          <b>New HashTags: {hashTags.length}</b>
          <b>HashTags: {_hashTags.length}</b>
          <b>Questions: {themeQuestions.length}</b>
          <b>Interview Questions: {interviewQuestions.length}</b>
          <hr />
        </div>
        <b>HashTags ({hashTags.length}):</b>
        {/* <div > */}
        {(_hashTags || []).map((_it, key) => {
          let it = tagsObj[_it] || {}
        let stItem =stats.hashTags[key] || {}
          return <div key={key}>
            <span onClick={() => {
              stats.hashTags[key].isOpen = !stats.hashTags[key].isOpen;
            //console.log('stat', stats.hashTags)
              setStats(stats)
              setCd(new Date())
            }}>{it.title} ({(themeObj[it._id] || []).length} / {(interviewsObj[it._id] || []).length} )</span>
            {!!stItem.isOpen && <div className='inter-details'>
              <b>Questions</b>
              {(themeObj[it._id] || []).map((it, key) => {
                return <div key={key}>
                  {it.name}

                </div>
              })}
              <b onClick={() => {
                stats.hashTags[key].isOpenAll = !stats.hashTags[key].isOpenAll;
                setStats(stats)
                setCd(new Date())
              }}>Interviews Questinos(all)</b>
              {!!stats.hashTags[key].isOpenAll && <div className='inter-details2'>
                {(interviewsObj[it._id] || []).map((it, key) => {
                  return <div key={key}>
                    {it.name}

                  </div>
                })}
              </div>}
            </div>}
          </div>
        })}

        <b>Interviews ({interviews.length}):</b>
        {(interviews || []).map((it, key) => {
          return <div key={key}>
            {toOdb(it.cd)} #{it._id} {it.name}
          </div>
        })}

        <b>ThemeQuestions ({themeQuestions.length}):</b>
        {(themeQuestions || []).map((it, key) => {
          return <div key={key}>
            {it.name}
          </div>
        })}

        <b>InterviewQuestions ({interviewQuestions.length}):</b>
        {(interviewQuestions || []).map((it, key) => {
          return <div key={key}>
            {it.name}
          </div>
        })}
      </div>
    </div>
  </div>
}

export {toOdb}
export default Stats
